import { Frame, Element, useEditor } from "@craftjs/core";
import { Typography, Paper, Grid, makeStyles } from "@material-ui/core";
import {React, useEffect} from "react";

import { SettingsPanel } from "./components/SettingsPanel";
import { Toolbox } from "./components/Toolbox";
import { Topbar } from "./components/Topbar";
import { Container } from "./components/user/Container";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    background: "rgb(252, 253, 253)",
  },
}));

export default function App() {

  const { actions: {deserialize} } = useEditor();

  const classes = useStyles();

  useEffect(() => {

    window.addEventListener('message', (event) =>  {
console.log(event.origin);
console.log(event.data);
//      if (event.origin !== process.env.REACT_APP_FLOW_URL) {

        const data = event.data;
        
        if (data) {
          deserialize(data);
        }
       
  //    }

    });
   
  }, );
      
  return (
    <div style={{ margin: "0 auto" }}>
      <Typography style={{ margin: "20px 0" }} variant="h5" align="center">
        Variant Flow
      </Typography>
        <Topbar />
        <Grid container spacing={2} style={{ paddingTop: "10px" }}>
          <Grid item xs={3}>
            <Paper className={classes.root}>
              <Toolbox />
            </Paper>
          </Grid>
          <Grid item xs>

          <Frame >
              <Element
                  canvas
                  is={Container}
                  padding={5}
                  width={500}
                  height={300}
                  background="#eeeeee"
                  data-cy="root-container"
                ></Element>
            </Frame>
           
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.root}>
              <SettingsPanel />
            </Paper>
          </Grid>
        </Grid>
    </div>
  );
}
